export const projects = [
  {
    title: "Python 3 Tutorials",
    about: [
      "Starter to intermediate python 3 tutorials with examples. Including: Features, Basic Syntax, Data Structures, Functions, Modules, OOP, etc.",
      "The repository includes MD, ODP, PDF and python example files. with dark and light themes.",
    ],
    stack: ["Python", "mkdocs", "GruvDoc"],
    links: [
      {
        title: "amiri.cc/python3tutorials/",
        href: "https://amiri.cc/python3tutorials/",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-blue-0",
        ringColor: "ring-blue-0",
      },
      {
        title: "github.com/aasmpro/python3tutorials",
        href: "https://github.com/aasmpro/python3tutorials",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-blue-0",
        ringColor: "ring-blue-0",
      },
    ],
    orderProjects: 180,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Django Zero Settings",
    about: [
      "Django util for managing app settings, like how DjangoRestFramework do it.",
      "Supports defaults, global/local user settings override, auto import, removed settings and pre checks.",
    ],
    stack: ["Python", "Django"],
    links: [
      {
        title: "pypi.org/project/django-zero-settings/",
        href: "https://pypi.org/project/django-zero-settings/",
        titleInfo: "PyPI",
        icon: "fab fa-python",
        bgColor: "bg-green-0",
        ringColor: "ring-green-0",
      },
      {
        title: "github.com/aasmpro/django-zero-settings",
        href: "https://github.com/aasmpro/django-zero-settings",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-green-0",
        ringColor: "ring-green-0",
      },
    ],
    orderProjects: 200,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Zero Resume",
    about: [
      "Yet another Django-based resume content provider with RestFul APIs and Admin panel, including Sections, Links, CoverLetters, etc.",
      "It has its own frontend project, developed with React."
    ],
    stack: ["Python", "Django"],
    links: [
      {
        title: "github.com/aasmpro/zero-resume",
        href: "https://github.com/aasmpro/zero-resume",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-green-0",
        ringColor: "ring-green-0",
      },
    ],
    orderProjects: 190,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "GruvDoc",
    about: [
      "Pre-configured MkDocs project with all the must useful extensions and tweaks needed, just Fork, Change and Push!",
      "It tweaks `Material for MkDocs` with `GruvBox` color palette and `JetBrainsMono` font, includes workflows for github/gitlab and adds a few styles and utils.",
    ],
    stack: ["Python", "mkdocs", "mkdocs-material"],
    links: [
      {
        title: "amiri.cc/gruvdoc/",
        href: "https://amiri.cc/gruvdoc/",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-green-0",
        ringColor: "ring-green-0",
      },
      {
        title: "github.com/aasmpro/gruvdoc",
        href: "https://github.com/aasmpro/gruvdoc",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-green-0",
        ringColor: "ring-green-0",
      },
    ],
    orderProjects: 195,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Django Smart Share",
    about: [
      "Simple Django app for sharing and managing files/directories of local machine or server over HTTP protocol.",
    ],
    stack: ["Python", "Django", "os", "shutil"],
    links: [
      {
        title: "pypi.org/project/djangosmartshare/",
        href: "https://pypi.org/project/djangosmartshare/",
        titleInfo: "PyPI",
        icon: "fab fa-python",
        bgColor: "bg-green-0",
        ringColor: "ring-green-0",
      },
      {
        title: "github.com/aasmpro/djangosmartshare",
        href: "https://github.com/aasmpro/djangosmartshare",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-green-0",
        ringColor: "ring-green-0",
      },
    ],
    orderProjects: 190,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Simple SM",
    about: [
      "Simple state machines for python, that use dictionaries for creating states.",
    ],
    stack: ["Python"],
    links: [
      {
        title: "pypi.org/project/simplesm/",
        href: "https://pypi.org/project/simplesm/",
        titleInfo: "PyPI",
        icon: "fab fa-python",
        bgColor: "bg-blue-0",
        ringColor: "ring-blue-0",
      },
      {
        title: "github.com/aasmpro/simplesm",
        href: "https://github.com/aasmpro/simplesm",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-blue-0",
        ringColor: "ring-blue-0",
      },
    ],
    orderProjects: 170,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Simple Socket",
    about: [
      "Simple implementation of python socket for creating server/client programs supporting threading.",
    ],
    stack: ["Python", "socket", "threading"],
    links: [
      {
        title: "pypi.org/project/simplesocket/",
        href: "https://pypi.org/project/simplesocket/",
        titleInfo: "PyPI",
        icon: "fab fa-python",
        bgColor: "bg-blue-0",
        ringColor: "ring-blue-0",
      },
      {
        title: "github.com/aasmpro/simplesocket",
        href: "https://github.com/aasmpro/simplesocket",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-blue-0",
        ringColor: "ring-blue-0",
      },
    ],
    orderProjects: 160,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Xurl",
    about: [
      "Command-line tool for extracting links (href data) from HTML files/Web pages.",
    ],
    stack: ["Python", "requests", "argparse"],
    links: [
      {
        title: "pypi.org/project/xurl/",
        href: "https://pypi.org/project/xurl/",
        titleInfo: "PyPI",
        icon: "fab fa-python",
        bgColor: "bg-dark-0",
        ringColor: "ring-dark-0",
      },
      {
        title: "github.com/aasmpro/xurl",
        href: "https://github.com/aasmpro/xurl",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-dark-0",
        ringColor: "ring-dark-0",
      },
    ],
    orderProjects: 140,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "DotFiles",
    about: [
      "Almost everything from Scripts, configs, docs, thoughts, and anything that might be useful later, exists here.",
    ],
    stack: ["Python", "mkdocs"],
    links: [
      {
        title: "amiri.cc/dotfiles/",
        href: "https://amiri.cc/dotfiles/",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-dark-0",
        ringColor: "ring-dark-0",
      },
      {
        title: "github.com/aasmpro/dotfiles",
        href: "https://github.com/aasmpro/dotfiles",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-dark-0",
        ringColor: "ring-dark-0",
      },
    ],
    orderProjects: 150,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
  {
    title: "Randomize",
    about: [
      "Time for Fun?! with randomize, you can make your options and the numbers of them, then shuffle and show them in secret cards. each card can be selected once. I have made this project to cut the preparation time for playing group games.",
    ],
    stack: ["JS/TS", "React", "antd"],
    links: [
      {
        title: "amiri.cc/randomize/",
        href: "https://amiri.cc/randomize/",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-red-0",
        ringColor: "ring-red-0",
      },
      {
        title: "github.com/aasmpro/randomize",
        href: "https://github.com/aasmpro/randomize",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-red-0",
        ringColor: "ring-red-0",
      },
    ],
    orderProjects: 130,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Truth or Dare",
    about: [
      "Time for Fun again?! well this app lets you play truth-or-dare without a bottle (it's always hard to spin a bottle).",
    ],
    stack: ["Python", "JS", "Vue", "Bootstrap"],
    links: [
      {
        title: "amiri.cc/truthordare/",
        href: "https://amiri.cc/truthordare/",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-red-0",
        ringColor: "ring-red-0",
      },
      {
        title: "github.com/aasmpro/truthordare",
        href: "https://github.com/aasmpro/truthordare",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-red-0",
        ringColor: "ring-red-0",
      },
    ],
    orderProjects: 120,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: true,
  },
  {
    title: "Svpn",
    about: ["An openconnect wrapper, with config files feature."],
    stack: ["Shell"],
    links: [
      {
        title: "github.com/aasmpro/svpn",
        href: "https://github.com/aasmpro/svpn",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-dark-0",
        ringColor: "ring-dark-0",
      },
    ],
    orderProjects: 80,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
  {
    title: "Hunt The Wumpus",
    about: [
      "Simple game in which you should hunt the wumpus without being eaten or falling in pits. it is mostly shown as an example for AI courses. here you can play the game or write a code that plays the game using its APIs.",
    ],
    stack: ["JS/TS", "Vue", "Bootstrap"],
    links: [
      {
        title: "amiri.cc/huntthewumpus/",
        href: "https://amiri.cc/huntthewumpus/",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-red-0",
        ringColor: "ring-red-0",
      },
      {
        title: "github.com/aasmpro/huntthewumpus",
        href: "https://github.com/aasmpro/huntthewumpus",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-red-0",
        ringColor: "ring-red-0",
      },
    ],
    orderProjects: 70,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
  {
    title: "Behdar",
    about: [
      "Drugstores management system, as a windows application that includes Users, Drugs, Drug Factories, Insurances, and Prescriptions.",
    ],
    stack: ["C#", "WindowsForm", "SqlServer"],
    links: [
      {
        title: "github.com/aasmpro/behdar",
        href: "https://github.com/aasmpro/behdar",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-purple-0",
        ringColor: "ring-purple-0",
      },
    ],
    orderProjects: 0,
    orderExperiences: 0,
    showProjects: false,
    showExperiences: false,
  },
  {
    title: "Personal Sites",
    about: ["I have made a bunch of personal sites and portfolios too."],
    stack: ["JS/TS", "React", "Sass", "ThreeJS"],
    links: [
      {
        title: "github.com/aasmpro/aasmpro.github.io",
        href: "https://github.com/aasmpro/aasmpro.github.io",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "amiramiri.ir",
        href: "https://amiramiri.ir",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/amiramiri.ir",
        href: "https://github.com/aasmpro/amiramiri.ir",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "melikaamirhoseini.ir",
        href: "https://melikaamirhoseini.ir",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/melikaamirhoseini.ir",
        href: "https://github.com/aasmpro/melikaamirhoseini.ir",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "panizmohebbi.ir",
        href: "https://panizmohebbi.ir",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/panizmohebbi.ir",
        href: "https://github.com/aasmpro/panizmohebbi.ir",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
    ],
    orderProjects: 0,
    orderExperiences: 0,
    showProjects: false,
    showExperiences: true,
  },
  {
    title: "amiri.cc",
    about: ["Current site! ;] my resume and portfolio. Hosted by github pages."],
    stack: ["React", "Tailwind"],
    links: [
      {
        title: "amiri.cc",
        href: "https://amiri.cc",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/aasmpro.github.io",
        href: "https://github.com/aasmpro/aasmpro.github.io",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
    ],
    orderProjects: 118,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
  {
    title: "FatemehAmirhosseini.ir",
    about: ["Fatemeh Amirhosseini personal site.", "It's using ZeroResume project as backend."],
    stack: ["Python", "Django", "React", "Tailwind"],
    links: [
      {
        title: "fatemehamirhosseini.ir",
        href: "https://fatemehamirhosseini.ir",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/fatemehamirhosseini.ir",
        href: "https://github.com/aasmpro/fatemehamirhosseini.ir",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
    ],
    orderProjects: 115,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
  {
    title: "AmirAmiri.ir",
    about: ["Amir Amiri personal site."],
    stack: ["JS/TS", "React", "Sass", "ThreeJS"],
    links: [
      {
        title: "amiramiri.ir",
        href: "https://amiramiri.ir",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/amiramiri.ir",
        href: "https://github.com/aasmpro/amiramiri.ir",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
    ],
    orderProjects: 100,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
  {
    title: "MelikaAmirhoseini.ir",
    about: ["Melika Amirhoseini personal site."],
    stack: ["JS/TS", "React", "Sass"],
    links: [
      {
        title: "melikaamirhoseini.ir",
        href: "https://melikaamirhoseini.ir",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/melikaamirhoseini.ir",
        href: "https://github.com/aasmpro/melikaamirhoseini.ir",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
    ],
    orderProjects: 110,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
  {
    title: "PanizMohebbi.ir",
    about: ["Paniz Mohebbi personal site."],
    stack: ["JS/TS", "React", "Sass"],
    links: [
      {
        title: "panizmohebbi.ir",
        href: "https://panizmohebbi.ir",
        titleInfo: "WebPage",
        icon: "fas fa-link",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
      {
        title: "github.com/aasmpro/panizmohebbi.ir",
        href: "https://github.com/aasmpro/panizmohebbi.ir",
        titleInfo: "GitHub",
        icon: "fab fa-github-alt",
        bgColor: "bg-orange-0",
        ringColor: "ring-orange-0",
      },
    ],
    orderProjects: 90,
    orderExperiences: 0,
    showProjects: true,
    showExperiences: false,
  },
];
