export const experiencesEN = [
  {
    title: "FullStack Developer",
    company: "TrustYou",
    startDate: "Apr 2022",
    endDate: "present",
    type: "Full-time",
    location: "Munich, Bavaria, Germany",
    about: [
      "TrustYou is the world's largest feedback platform, aims to optimize the travel experience from the right hotel to the perfect stay.",
      "To achieve this, the company cooperates with hotels, destinations and travel websites. TrustYou analyzes hundreds of millions of traveler reviews, turns them into actionable insights, and visualizes data for 500,000 hotels.",
      "As a result, travel websites can improve the travel planning process and hotels can offer their guests the best possible experience.",
    ],
    responsible: "Responsibilities:",
    responsibilities: [],
    contribution: "Contributions:",
    contributions: [
      "Develop & maintain direct response features for Google and Booking.com",
      "Maintain & refactor legacy apps like Alerting system",
      "Maintain & refactor operations system",
    ],
    achievement: "Achievements:",
    achievements: [],
    stack: [
      "Python",
      "FastAPI",
      "Django",
      "Flask",
      "React",
      "Docker",
      "Kubernetes",
    ],
    links: [
      {
        title: "trustyou.com",
        href: "https://www.trustyou.com",
      },
    ],
    showExperiences: true,
    showExperiencesPrint: true,
  },
  {
    title: "CTO - Web Developer",
    company: "SnappCarFix / CarFillo",
    startDate: "Sep 2019",
    endDate: "Apr 2022",
    type: "Full-time",
    location: "Isfahan, Iran",
    about: [
      "SnappCarFix is an online platform for Car parts and services, backed by Snapp (Uber clone).",
      "It includes web apps for Stores, ServiceCenters, Agents, and Sellers, to offer products/services like oil, battery, engine parts, car wash, etc, with 7 delivery/service solutions per product.",
      "CarFillo sells products to service centers, it's an Online Shop, which service centers gain credits here based on their services on SnappCarFix.",
    ],
    responsible: "Responsibilities:",
    responsibilities: [
      "Leading developers team (10-15 developers)",
      "Develop and deliver platform",
      "Specifying platform technical structure and tools",
      "Managing and targeting technical parts of contracts",
    ],
    contribution: "Contributions:",
    contributions: [
      "Restructured the platform from WordPress and .net to Python/Django/React, handled more requests, with better UI/UX, and fewer development hassles",
      "Changed development process to a TDD version, managed with Agile methodologies",
      "Specified an interview to the onboarding process, interviewed over 50 people, and hired 20",
      "Replaced ServiceCenters app with PWA, gained real-time updates, 60% faster service submits",
      "Created Agents app, made data gathering 80% faster and completely paperless",
      "Developed a core module to structure both Django and React projects and utilities",
      "Restructured DevOps solutions, added cache layers, Docker, GitLab CI/CD, monitoring, logging, and backup plans, gained 10k RPS handling and 50% faster response times",
      "Created Accounting and Logistic modules, decreased manual work and human mistakes",
      "Created Sellers platform to gain faster products warehouse stock and price updates",
      "Rewritten Panda project to Matrix, with Django 3.2, added Kubernetes and secured credentials",
      "Rewritten T2Bco project to Radix, from .net to Python/Django/React",
    ],
    achievement: "Achievements:",
    achievements: [
      "Standing on top of quick service e-commerce in the Middle East",
      "Recording over 1.5 million users and their vehicle data",
      "Recording over 16,000 service centers in the whole country",
      "Recording over 15,000 online orders and 2,500 services per day",
      "Upgrading e-commerce share of GDP by 1.5%, decreasing logistic costs by 45%",
    ],
    stack: ["Python", "Django", "React", "Memcached", "Celery", "Docker"],
    links: [
      {
        title: "snappcarfix.com",
        href: "https://snappcarfix.com",
      },
      {
        title: "ag.snappcarfix.com",
        href: "https://ag.snappcarfix.com",
      },
      {
        title: "sc.snappcarfix.com",
        href: "https://sc.snappcarfix.com",
      },
      {
        title: "carfillo.com",
        href: "https://carfillo.com",
      },
    ],
    showExperiences: true,
    showExperiencesPrint: true,
  },
  {
    title: "Web Developer",
    company: "Press'nXPress",
    startDate: "Jun 2019",
    endDate: "Nov 2019",
    type: "Part-time (Remote)",
    location: "Ontario, Canada",
    about: [
      "Press'nXPress is monitoring & measuring feedbacks in the real-time platform which gathers data from multiple sources like Terminals and Chips our company created, SMS, Voice, etc.",
      "Data will be stored and analyzed to multiple charts letting users perform needed queries. We had customers like Airlines, Governments, Malls, and Gyms.",
    ],
    responsible: "Responsibilities:",
    responsibilities: [],
    contribution: "Contributions:",
    contributions: [
      "Developed backend, frontend, and admin panels of scanortap with NestJS and React",
      "Refactored some parts of main platform from SailJS to NestJS and Angular to React",
    ],
    achievement: "Achievements:",
    achievements: [],
    stack: ["JS/TS", "NestJS", "React", "Angular", "MySQL"],
    links: [
      {
        title: "pressnxpress.com",
        href: "https://pressnxpress.com",
      },
      {
        title: "scanortap.com",
        href: "https://scanortap.com",
      },
    ],
    showExperiences: true,
    showExperiencesPrint: true,
  },
  {
    title: "Web Developer",
    company: "Motosel",
    startDate: "Sep 2018",
    endDate: "Sep 2019",
    type: "Part-time",
    location: "Isfahan, Iran",
    about: [
      "A platform for car consumables which includes multiple web/mobile apps for ServiceCenters and Stores.",
      "The aim was to sell oil and related products to service centers with great offers, and increase their customers.",
      "This Company later split into two companies, CarFillo and SnappCarFix (backed by Snapp, Uber clone in Iran), which i'm working as CTO on both of them.",
    ],
    responsible: "Responsibilities:",
    responsibilities: [],
    contribution: "Contributions:",
    contributions: [
      "Refactored and restructured core backend project with Python/Django",
      "Developed a microservice to sync data between Stores and ServiceCenters",
      "Developed a real-time messaging module for in-app chats",
    ],
    achievement: "Achievements:",
    achievements: [],
    stack: ["Python", "Django", "JS/TS", "React", "ReactNative"],
    links: [],
    showExperiences: true,
    showExperiencesPrint: true,
  },
  {
    title: "Backend Developer",
    company: "Rahnema College",
    startDate: "Aug 2018",
    endDate: "Sep 2018",
    type: "Internship",
    location: "Tehran, Iran",
    about: [
      "Rahnama College is one of the most valuable internship programs in Iran, with multiple courses like Development, Digital Marketing and etc.",
      "They select 30 interns from over 1000 volunteers, after an online exam and in-person interview.",
      "The program contains 2 weeks of learning technical and soft skills (from DevOps to Teamwork) and 8 weeks of developing.",
    ],
    responsible: "Responsibilities:",
    responsibilities: [],
    contribution: "Contributions:",
    contributions: [
      "Developed & managed backend project (combination of Instagram and Pinterest features)",
      "Configured and deployed project on linux server with nginx and mysql",
    ],
    achievement: "Achievements:",
    achievements: [],
    stack: ["Python", "Django", "JS/TS", "ReactNative", "MySQL", "Redis"],
    links: [
      {
        title: "github.com/Akaskhoone/",
        href: "https://github.com/Akaskhoone/",
      },
      {
        title: "rahnemacollege.com",
        href: "https://rahnemacollege.com",
      },
    ],
    showExperiences: true,
    showExperiencesPrint: true,
  },
  {
    title: "Web Developer",
    company: "Saj Tech",
    startDate: "Apr 2016",
    endDate: "May 2017",
    type: "Full-time",
    location: "Mashhad, Iran",
    about: [
      "The platform was a location-based delivery and management application for stores, with ideas of delivery with drones.",
      "Unfortunately, this company went bankrupt because of a lack of funding after two years.",
    ],
    responsible: "Responsibilities:",
    responsibilities: [],
    contribution: "Contributions:",
    contributions: [
      "Developed users, warehouse modules using Django",
      "Developed APIs for admin panels and our Android app",
      "Developed 3 frontend projects including 2 websites and an Admin panel",
    ],
    achievement: "Achievements:",
    achievements: [],
    stack: ["Python", "Django", "JS", "HTML", "CSS", "MySQL"],
    links: [],
    showExperiences: true,
    showExperiencesPrint: true,
  },
];
