export const skillsEN = {
  title:
    "In summary, i have experience working with these tools and often use them:",
  data: [
    "Python",
    "Django, FastAPI",
    "JS, TS",
    "React",
    "Sass, Tailwind",
    "Docker",
    "GitHub/GitLab CI/CD",
    "Linux Servers",
    "A Bit DevOps ;)",
  ],
};
